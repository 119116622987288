import { register } from "register-service-worker";

/* if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js')
} */

if ('serviceWorker' in navigator) {
  register('/service-worker.js')
  let refreshing = false
  // This is triggered when a new service worker take over
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) return
    refreshing = true

    window.location.reload()
  });
}
