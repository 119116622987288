

































import Vue from "vue";
import Component from "vue-class-component";
import AuthService from "@/services/AuthService";
import VButton from "@/components/VButton.vue";

@Component({
  components: {
    VButton
  }
})
export default class Login extends Vue {
  username = ""
  password = ""
  loginError = ""
  appVersion = ""

  created() {
    this.appVersion = process.env.PACKAGE_VERSION
  }

  onLoginSubnmit() {
    AuthService.login(this.username, this.password)
      .then((response: any) => {
        const token: any = AuthService.decodeToken(response.data.bearer)
        if(token.user && (token.user.auth.includes('SU-ADMIN') || token.user.auth.includes('SU-REDEEMER'))){
          AuthService.setPrivileges('true')
        } else {
          AuthService.setPrivileges('false')
        }
        AuthService.setToken(response.data.bearer);
        this.$router.push("/scanner");
        AuthService.getInfoUser().then(data => {
          const user = data.data.name;
          AuthService.setUser(user);
        });
      })
      .catch((err: any) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error === "Bad credentials"
        ) {
          this.loginError = "LOGIN.USER_NOT_FOUND";
        } else {
          this.loginError = "LOGIN.GENERIC_ERROR";
        }
      });
  }
}
