import AxiosService from "@/services/AxiosService";
import API_URL from "@/settings/Setting";

const AuthService = {
  login(email: string, password: string) {
    const url = API_URL.LOGIN;
    return AxiosService.post(url, { email, password });
  },

  getInfoUser() {
    const url = API_URL.USER_INFO;
    return AxiosService.get(url);
  },

  logout() {
    this.removeToken();
    this.removeUser();
    AxiosService.removeHeader();
  },

  setToken(token: string) {
    localStorage.setItem("token", token);
  },

  setUser(user: any) {
    localStorage.setItem("user", user);
  },

  setTheme(theme: any) {
    localStorage.setItem("theme", theme);
  },

  setLanguage(lang: any) {
    localStorage.setItem("lang", lang);
  },
  setPrivileges(privileges: any) {
    localStorage.setItem("privileges", privileges);
  },

  setListProducts(listProducts: any) {
    localStorage.setItem("listProducts", listProducts);
  },

  getToken() {
    return localStorage.getItem("token");
  },

  getUser() {
    return localStorage.getItem("user");
  },

  getTheme() {
    return localStorage.getItem("theme");
  },

  getLanguage() {
    return localStorage.getItem("lang");
  },

  getPrivileges() {
    return localStorage.getItem("privileges");
  },

  getListProducts() {
    return localStorage.getItem("listProducts");
  },

  removeToken() {
    localStorage.removeItem("token");
  },

  removeUser() {
    localStorage.removeItem("user");
  },

  removeListProducts() {
    localStorage.removeItem("listProducts");
  },

  getExpirationToken() {
    let expired;
    const token = this.getToken();
    if (token) {
      const dateEpoch = new Date(0);
      const dateNow = new Date();
      const tokenInfo = token.split(".")[1];
      const decodedToken = atob(tokenInfo);
      const user = JSON.parse(decodedToken);
      dateEpoch.setUTCSeconds(user.exp);
      if (dateEpoch > dateNow) {
        AxiosService.setHeader();
        expired = false;
      } else {
        this.logout();
        expired = true;
      }
    } else {
      expired = true;
    }
    return expired;
  },

  decodeToken(token: string): any {
    const tokenInfo = token.split('.')[1]
    const decodedToken = atob(tokenInfo)
    return { user: JSON.parse(decodedToken) }
  },
};

export default AuthService;
