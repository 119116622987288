const API_URL = {
  LOGIN: `${process.env.VUE_APP_ROOT_API}/api/user/login`,
  USER_INFO: `${process.env.VUE_APP_ROOT_API}/api/user/info`,
  REDEEM: `${process.env.VUE_APP_ROOT_API}/api/sale/redeem`,
  OFFLINE: `${process.env.VUE_APP_ROOT_API}/api/offline/sale`,
  SCOPE_LIST: `${process.env.VUE_APP_ROOT_API}/api/scopes/with-sale-redemptions?startRedeemedDate=:startRedeemedDate&endRedeemedDate=:endRedeemedDate`,
  REDEEM_LIST: `${process.env.VUE_APP_ROOT_API}/api/reports/scope/:scopeId/with-total-sale-redemptions?startRedeemedDate=:startRedeemedDate&endRedeemedDate=:endRedeemedDate`,
  BARCODES_REDEEMED_BYSCOPE: `${process.env.VUE_APP_ROOT_API}/api/reports/scope/:scopeId/barcodes-redeemed?startRedeemedDate=:startRedeemedDate&endRedeemedDate=:endRedeemedDate&productId=:productId&productOptionId=:productOptionId&personTypeId=:personTypeId`,
};
export default API_URL;

