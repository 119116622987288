import axios from 'axios';
import AuthService from '@/services/AuthService';

const AxiosService = {
  init(baseURL: string) {
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource: any) {
    return axios.get(resource);
  },

  post(resource: any, data: any) {
    return axios.post(resource, data);
  },

  put(resource: any, data: any) {
    return axios.put(resource, data);
  },

  delete(resource: any) {
    return axios.delete(resource);
  },
};

export default AxiosService;
