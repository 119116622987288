








import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class VButton extends Vue {
  @Prop({ required: true }) text!: string;
  @Prop({ required: false }) disabled!: boolean;

  onClick() {
    this.$emit("clicked");
  }
}
