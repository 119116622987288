






import Vue from "vue";
import Component from "vue-class-component";
import AuthService from "./services/AuthService";
@Component({})
export default class App extends Vue {
  created() {
    const lang = AuthService.getLanguage();
    if (lang !== null && lang !== "") {
      AuthService.setLanguage(lang);
      this.$i18n.locale = lang;
    }
    // Fixed VH ios
    const root = document.documentElement;
    root.style.setProperty("--real-vh", window.innerHeight / 100 + "px");

    if (this.$route.query && this.$route.query.cex === null) {
      AuthService.setTheme("cex");
    } else {
      AuthService.setTheme("cs");
    }

    const theme = AuthService.getTheme();
    if (theme === "cex") {
      root.style.setProperty("--main-color", "#f1682b");
    }
  }
}
