import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'
import i18n from './plugins/i18n'
import { VueHammer } from 'vue2-hammer';
import AxiosService from '@/services/AxiosService';
import './registerServiceWorker';

// TODO: remove
import 'scanner-qr-component/src/scanner-qr-component.js';

Vue.config.productionTip = false;
Vue.use(VueHammer);
AxiosService.init(process.env.VUE_APP_ROOT_API);

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
